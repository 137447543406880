/**
 * Represents a plugin for handling authentication via promo codes.
 * This class provides methods to sign up and log in using promo codes by communicating with an authentication endpoint.
 */
export class AuthPromoCodePlugin {
  /**
   * Creates an instance of AuthPromoCodePlugin.
   * @param {Object} app - The application instance, used to access the client and renderer for API calls.
   */
  constructor(app) {
    this.app = app;
  }

  /**
   * Performs an action (signup or login) by calling the authentication endpoint with a promo code.
   * @async
   * @param {string} action - The action to perform ('signup' or 'login').
   * @param {string} promoCode - The promo code to be used for the action.
   * @returns {Promise<Object>} The result of the authentication action.
   * @throws {Error} Throws an error if the authentication endpoint is not found.
   */
  async callAction(action, promoCode) {
    const authEndpoint = this.app?.renderer?.a2u?.authUrl;

    if (!authEndpoint) {
      throw 'Auth endpoint not found';
    }

    return await this.app.app.client.call(`${authEndpoint}/auth-promo-code`, action, promoCode);
  }

  /**
   * Signs up a new user using their email address.
   * This method delegates the signup process to the `callAction` method, specifying 'signup' as the action
   * and passing the provided email address as the promo code parameter. It returns a promise that resolves
   * with the result of the signup action, which could include user details or error information depending
   * on the outcome of the authentication process.
   *
   * @async
   * @param {string} email - The email address of the user attempting to sign up.
   * @returns {Promise<Object>} A promise that resolves with the result of the signup action.
   */
  async signup(email) {
    return await this.callAction('signup', email);
  }

  /**
   * Logs in a user using a promo code.
   * @async
   * @param {string} promoCode - The promo code for logging in.
   * @returns {Promise<Object>} The result of the login action.
   */
  async login(promoCode) {
    return await this.callAction('login', promoCode);
  }
}
