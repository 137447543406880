import {ATUFunctionPackage} from "a2u-renderer-common/src/utils/processor/ATUFunctionPackage";
import {ATUFunctionResult} from "a2u-renderer-common/src/utils/processor/ATUFunctionResult";

export class Test extends ATUFunctionPackage {

    /**
     * Test function
     * @return {Promise<ATUFunctionResult>}
     * @constructor
     */
    async Test(){

        // Return result as two separate arrays
        return new ATUFunctionResult({})
    }
}
