import { defineCustomElements } from '@ionic/pwa-elements/loader';

/**
 * ImagePickerCustomElements class provides methods for handling custom elements in the Image Picker.
 */
export class ImagePickerCustomElements {
  /**
   * Initializes the custom elements for the Image Picker.
   *
   * This method tries to define the custom elements for the Image Picker using the 'defineCustomElements' function from '@ionic/pwa-elements/loader'.
   * If there is an error while defining the custom elements, it logs the error with the message 'ImagePickerCustomElements error:'.
   */
  init() {
    try {
      defineCustomElements(window);
    } catch (e) {
      console.error('ImagePickerCustomElements error:', e);
    }
  }
}
