import {BaseErrorHandler} from './BaseErrorHandler';

export class A2uErrorHandler extends BaseErrorHandler {
  async processError(err, componentInstance, info) {
    // Retrieves the debugLogger from the componentInstance's renderer's a2u object
    const debugLogger = componentInstance?.renderer?.a2u?.debugLogger || undefined;

    // If debugLogger is not available, the function returns
    if (!debugLogger) {
      return;
    }

    try {
      // If debugLogger is available, it logs an 'unhandled' event with the error message and additional data
      debugLogger.log({
        type: 'unhandled',
        message: err?.message || err,
        data: {
          info,
          component: { id: componentInstance?.block?.id },
        },
      });
    } catch (e) {
      console.error('A2uErrorHandler.processError', e);
    }
  }
}
