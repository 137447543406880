import {CdnLoader} from './CdnLoader.js';

/**
 * Class representing a CDN Manager.
 */
export class CdnManager {
  static instance = null;

  /**
   * Create a CDN Manager.
   * @param {Object} device - The device for the CDN Manager.
   */
  constructor(device) {
    this.device = device;
    this.runMode = null;
    this.platform = null;
    this.cdnServers = null;
  }

  /**
   * Get the instance of the CDN Manager.
   * If the instance does not exist, it creates a new instance with the device as the argument.
   * @param {Object} device - The device for the CDN Manager.
   * @returns {CdnManager} The instance of the CDN Manager.
   * @static
   */
  static getInstance(device) {
    if(!this.instance) {
      this.instance = new this(device)
    }

    return this.instance;
  }

  /**
   * Initialize the CDN Manager.
   * @param {Object} options - The options for the CDN Manager.
   * @param {string} options.runMode - The run mode for the CDN Manager.
   * @param {string} options.platform - The platform for the CDN Manager.
   * @param {string|Object} options.cdnServers - The CDN servers for the CDN Manager.
   */
  init({runMode, platform, cdnServers}) {
    this.runMode = runMode;
    this.platform = platform;
    this.cdnServers = cdnServers;
  }

  /**
   * Get the CDN server.
   * If the 'cdnServers' property is a string, it returns the 'cdnServers' property.
   * If the 'cdnServers' property is an object, it returns the property of the 'cdnServers' object that corresponds to the 'runMode' property.
   * Otherwise, it returns null.
   * @returns {string|null} The CDN server.
   */
  get cdnServer() {
    // If the CDN URL is defined in the environment variables, return it
    if (process.env.VUE_APP_CDN_URL) {
      return process.env.VUE_APP_CDN_URL.replace(/\/$/, '');
    }

    if (typeof this.cdnServers === 'string') {
      return this.cdnServers.replace(/\/$/, '');
    }

    if (typeof this.cdnServers === 'object') {
      const uri = this.cdnServers[this.runMode] || null;

      if (!uri) {
        return null;
      }

      return `${uri.replace(/\/$/, '')}/storage/download`;
    }

    return null;
  }

  /**
   * Load the file from the CDN.
   * If the 'cdnServer' property is falsy, it logs an error message and returns null.
   * It creates a new 'CdnLoader' object with the 'manager' property set to the 'CdnManager' object, the 'src' property set to the 'src' parameter, the 'timeout' property set to the 'timeout' parameter, and the 'progressCallback' property set to the 'progressCallback' parameter.
   * It calls the 'load' method of the 'CdnLoader' object and returns the result.
   * If an error occurs during the execution of the method, it logs an error message and returns null.
   * @param {string} src - The source of the file.
   * @param {number} [timeout=30] - The timeout for the loading.
   * @param {Function} [progressCallback=undefined] - The progress callback for the loading.
   * @returns {Promise<string|null>} A promise that resolves to the URI of the file or null.
   * @async
   */
  async load(src, timeout = 30, progressCallback = undefined) {
    try {
      if (!this.cdnServer) {
        console.error('CDN server is not defined');

        return null;
      }

      const loader = new CdnLoader({
        manager: this,
        src,
        timeout,
        progressCallback,
      });

      return await loader.load();
    } catch (e) {
      console.error(`Error loading remote resource: ${src}`, e);

      return null;
    }
  }
}
