/**
 * Class representing a CDN Loader.
 */
export class CdnLoader {
  /**
   * Create a CDN Loader.
   * @param {Object} options - The options for the CDN Loader.
   * @param {Object} options.manager - The manager for the CDN Loader.
   * @param {string} options.src - The source for the CDN Loader.
   * @param {number} options.timeout - The timeout for the CDN Loader.
   * @param {Function} options.progressCallback - The progress callback for the CDN Loader.
   */
  constructor({manager, src, timeout, progressCallback}) {
    this.manager = manager;
    this.src = src;
    this.timeout = timeout;
    this.progressCallback = progressCallback;

    this.cdnPath = `${this.manager.cdnServer}/${this.src}`;
  }

  /**
   * Load the file from the CDN or the local file system.
   * If a progress callback function is provided, it is called with the number 100.
   * If the 'runMode' property of the 'manager' object is 'debug', it returns the path of the file in the storage.
   * Otherwise, it returns the path of the file in the CDN.
   *
   * @async
   * @returns {string} The URI of the file.
   */
  async load() {
    if (typeof this.progressCallback === 'function') {
      this.progressCallback(100);
    }

    if (this.manager.runMode === 'debug') {
      return `${process.env.VUE_APP_STORAGE_PATH.replace(/\/$/, '')}/${this.src}`;
    }

    return this.cdnPath;
  }
}
