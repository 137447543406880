const DB_NAME = 'filesStore';
const TABLE_NAME = 'files';

/**
 * IndexedDbFilesManager class.
 *
 * This class provides static methods to interact with an IndexedDB database.
 */
export class IndexedDbFilesManager {
  /**
   * Opens the IndexedDB database.
   *
   * This method attempts to open the 'filesStore' IndexedDB database.
   * If the database does not exist, it creates it and also creates an object store named 'files' with 'id' as the key path and auto increment enabled.
   * It returns a promise that resolves to the opened database.
   *
   * @static
   * @returns {Promise<IDBDatabase>} A promise that resolves to the opened database.
   */
  static openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(TABLE_NAME)) {
          db.createObjectStore(TABLE_NAME, { keyPath: 'id', autoIncrement: true });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  /**
   * Saves a file in the IndexedDB database.
   *
   * This method first opens the 'filesStore' IndexedDB database.
   * It then starts a readwrite transaction on the 'files' object store and attempts to add the provided blob to the store.
   * It returns a promise that resolves to the ID of the added file.
   *
   * @static
   * @param {Blob} blob - The blob of the file to save.
   * @returns {Promise<number>} A promise that resolves to the ID of the added file.
   */
  static async saveFile(blob) {
    const db = await this.openDatabase();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(TABLE_NAME, 'readwrite');
      const store = transaction.objectStore(TABLE_NAME);
      const request = store.add({ blob: blob });

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  /**
   * Retrieves a file from the IndexedDB database.
   *
   * This method first opens the 'filesStore' IndexedDB database.
   * It then starts a readonly transaction on the 'files' object store and attempts to get the file with the provided ID.
   * It returns a promise that resolves to the blob of the retrieved file.
   *
   * @static
   * @param {number} id - The ID of the file to retrieve.
   * @returns {Promise<Blob>} A promise that resolves to the blob of the retrieved file.
   */
  static async getFile(id) {
    const db = await this.openDatabase();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(TABLE_NAME, 'readonly');
      const store = transaction.objectStore(TABLE_NAME);
      const request = store.get(id);

      request.onsuccess = (event) => {
        resolve(event.target.result?.blob);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
}
